import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const ARTICLE_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment articleGenericPageHeaderFragment on Article {
    uid
    name
    subtitle
    authorName
    publicationTime
    featured
    images {
      ...fileResourceBaseFragment
    }
    updatedTime
    __typename
  }
    ${FILE_RESOURCE_BASE_FRAGMENT}
`;
